import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Pagination, PaginationItem } from '@material-ui/lab';

import { Box, Typography, List, ListItem, ListSubheader, Divider, ListItemIcon, ListItemText, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

import { getUrl, postUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Question({productId}) {
    // console.log(productId);

    const styles = useStyles();
    const { t } = useTranslation();

    const { accessToken } = useSelector(state => state.general);
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    const [question, setQuestions] = useState({});
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);
    const [questionField, setQuestionField] = useState();
    
    const callProductQuestion = () => {
        setLoading(true);
        getUrl(`questions/${productId}?page=${page}`)
        .then(questionData => {
            console.log("questionData", questionData)
            if (isMountedRef.current) {
                setLoading(false);
                setQuestions(questionData.data.data);
                setLastPage(questionData.data.last_page);
            }
        }).catch((error) => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    useEffect(() => {
        isMountedRef.current = true;
        callProductQuestion();
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [productId, page]);

    const submitQuestion = () => {
        if(questionField) {
            setLoading(true);
            postUrl(`questions/${productId}`, {
                question: questionField
            }).then(questionData => {
                console.log("questionData", questionData)
                if (isMountedRef.current) {
                    setLoading(false);
                    // setQuestions(questionData.data.data);
                    // setLastPage(questionData.data.last_page);
                    setQuestionField('');
                    addAlert(t('item.questionSubmit'), 'success');
                    callProductQuestion();
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
        } else {
            addAlert(t('item.invalidQuestion'));
        }
    }

    return (
        <Box maxWidth='100%' margin='0 auto' paddingTop={1} paddingBottom={1} style={{ backgroundColor: '#fff' }}>
            <List 
                style={{ padding: 5 }}
                subheader={<ListSubheader style={{ padding: 10, position:'unset' }}><Typography className={styles.title}>{ t('item.qna') }</Typography></ListSubheader>}
            >
                {
                    accessToken ?
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" paddingTop={1} paddingBottom={1}>
                        <TextField 
                            variant="outlined"
                            size="small"
                            fullWidth
                            multiline
                            rowsMax={5}
                            style={{ paddingRight: 8 }}
                            placeholder={ t('item.questionPlaceholder') }
                            value={questionField}
                            onChange={({ target }) => setQuestionField(target.value) }
                        />
                        <Button size="small" variant="contained" color="primary" onClick={submitQuestion}>
                            <Typography variant="button" style={{ fontSize: 10, color: '#FFFFFF' }}>{ t('item.askQuestion') }</Typography>
                        </Button>
                    </Box>
                    : 
                    <ListItem>
                        <Typography variant="caption" style={{ fontSize: 14 }}>{ t('item.noLogin') }</Typography>
                    </ListItem>
                }
                <Divider />
                {
                    _.size(question) > 0 ?
                        _.map(question, questionItem => {
                            return (
                                <Box key={questionItem.id}>
                                    <ListItem style={{ paddingBottom: 0 }}>
                                        <ListItemIcon><LiveHelpIcon color="primary" /></ListItemIcon>
                                        <ListItemText 
                                            primary={questionItem.question}
                                            secondary={`${questionItem.customer_name} - ${questionItem.created_date_display}`}
                                            primaryTypographyProps={{ variant: 'caption', style: { fontSize: 14 } }}
                                            secondaryTypographyProps={{ variant: 'caption', style: { fontSize: 10 } }}
                                        />
                                    </ListItem>
                                    {
                                        questionItem.answer ?
                                        <ListItem style={{ paddingTop: 0 }}>
                                            <ListItemIcon><InfoIcon color="primary" /></ListItemIcon>
                                            <ListItemText 
                                                primary={questionItem.answer}
                                                secondary={`${questionItem.answerer} - ${questionItem.answered_date_display}`}
                                                primaryTypographyProps={{ variant: 'caption', style: { fontSize: 14 } }}
                                                secondaryTypographyProps={{ variant: 'caption', style: { fontSize: 10 } }}
                                            />
                                        </ListItem>
                                        : null
                                    }
                                    <Divider />
                                </Box>
                            )
                        }) 
                    : 
                    <Box  width="100%" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <WebpImg src="/images/general/nodata3.png" alt="noimage" style={{ width: '150px', maxWidth: '100%' }} />
                        <Typography variant="body2">{t('item.noQuestion')}</Typography>
                    </Box>
                }
            </List>
            {
                lastPage > 1 ?
                <Box display="flex" justifyContent="center">
                    <Pagination count={lastPage} shape="rounded" page={page}
                        onChange={(event, value) => {
                            setPage(value);
                        }}
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                            />
                        )}
                    />
                </Box>
                : null
            }
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        color: '#525252',
        margin: 0,
        paddingRight: 7,
        fontSize: 14,
        lineHeight: 2,
        textTransform: 'uppercase'
    },
    subTitle: {
        margin: 0,
        paddingRight: 7,
        fontSize: 18,
        lineHeight: 1.5
    },
}));