import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Rating, Pagination, PaginationItem } from '@material-ui/lab';

import StarIcon from '@material-ui/icons/Star';
import PersonIcon from '@material-ui/icons/Person';

import _ from 'lodash';
import WebpImg from './Layouts/WebpImg';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Button, Box, Divider, Avatar } from '@material-ui/core';

import { getUrl } from '../helper/ApiAction';
import useNotificationLoading from '../helper/useNotificationLoading';

export default function Review(productId) {
    const [review, setReview] = useState({});
    const [reviewSort, setReviewSort] = useState('all');
    const [productReviewSummary, setProductReviewSummary] = useState({
        all_count: 0,
        average_rating: 0,
        comment_count: 0,
        total_sold: 0,
        star_count: {
            1: 0, 2: 0, 3: 0, 4: 0, 5: 0,
        }
    });
    const [lastPage, setLastPage] = useState(0);
    const [page, setPage] = useState(1);

    let { id } = useParams();
    const { t } = useTranslation();
    const styles = useStyles();
    const theme = useTheme();
    const isMountedRef = useRef(null);
    const { addAlert, setLoading } = useNotificationLoading();

    id = id ? id : productId;

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}/summary`)
            .then(reviewSummary => {
                setProductReviewSummary(reviewSummary.data);
                console.log("reviewSummary", reviewSummary);
            })
            .catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, setLoading, addAlert]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`reviews/${id}?sort=${reviewSort}&page=${page}`)
            .then(reviewData => {
                console.log("reviewData", reviewData)
                if (isMountedRef.current) {
                    setLoading(false);
                    setReview(reviewData.data.data);
                    setLastPage(reviewData.data.last_page);
                }
            }).catch((error) => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });

        return () => { isMountedRef.current = false };
    }, [id, reviewSort, page, setLoading, addAlert])

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    return (
        <Box maxWidth='100%' margin='0 auto' padding="0 15px" style={{ backgroundColor: '#fff' }}>
            <Box padding={1} display="flex" justifyContent="space-between">
                <Typography className={styles.title}>{t('item.rating')}</Typography>
                <Box display="flex" alignItems="center" paddingBottom={1}>
                    <Box display="flex" alignItems='center'>
                        <Typography className={styles.subTitle} style={{ color: '#777' }}>{productReviewSummary.average_rating}</Typography>
                        <Rating name="half-rating-read" value={productReviewSummary.average_rating} defaultValue={productReviewSummary.average_rating} precision={0.1} readOnly />
                    </Box>

                    <Typography className={styles.seperator}>|</Typography>

                    <Box display="flex" alignItems='center'>
                        <Typography className={styles.subTitle} style={{ fontSize: 18, color: '#ea8012' }}>
                            {productReviewSummary.all_count}
                        </Typography>
                        <Typography className={styles.subTitle} style={{ fontSize: 14, color: '#a0a0a0' }}>
                            {t('item.rating')}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={1}>
                {
                    _.map(['all', 'comment'], sortItem => {
                        return (
                            <Button
                                key={sortItem}
                                color={reviewSort === sortItem ? 'primary' : 'default'}
                                onClick={() => {
                                    setPage(1)
                                    setReviewSort(sortItem)
                                }}
                                className={styles.buttonStyle}
                                style={{ borderColor: reviewSort === sortItem ? theme.palette.primary.main : '#dadada' }}
                            >
                                <Box display="flex" justifyContent='center' flexDirection="column">
                                    <Typography style={{ fontSize: 11 }}>{t(`item.${sortItem}`)}</Typography>
                                    {
                                        sortItem === 'all' ?
                                            <Typography className={styles.sortingTitle}>{`(${productReviewSummary.all_count})`}</Typography>
                                            : null
                                    }
                                    {
                                        sortItem === 'comment' ?
                                            <Typography className={styles.sortingTitle}>{`(${productReviewSummary.comment_count})`}</Typography>
                                            : null
                                    }

                                </Box>
                            </Button>
                        )
                    })
                }
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" marginTop={1}>
                {
                    _.map([5, 4, 3, 2, 1], starSort => {
                        return (
                            <Button
                                key={`${starSort}star`}
                                color={reviewSort === `${starSort}star` ? 'primary' : 'default'}
                                className={styles.buttonStyle}
                                style={{ borderColor: reviewSort === `${starSort}star` ? theme.palette.primary.main : '#dadada' }}
                                onClick={() => {
                                    setPage(1)
                                    setReviewSort(`${starSort}star`)
                                }}
                            >
                                <Box display="flex" justifyContent="center" flexDirection="column">
                                    <Box display="flex" flexDirection="row">
                                        {_.map(_.range(starSort), starRangeItem => {
                                            return (
                                                <StarIcon key={`star_range_${starRangeItem}`} style={{ fontSize: 12, color: '#ffb400' }} />
                                            )
                                        })}
                                    </Box>
                                    <Typography className={styles.sortingTitle}>{`(${productReviewSummary.star_count[starSort]})`}</Typography>
                                </Box>
                            </Button>
                        )
                    })
                }
            </Box>
            <Divider style={{ margin: '15px 0' }} />
            {
                _.size(review) > 0 ?
                    <Box padding={2}>
                        {
                            _.map(review, reviewItem => {
                                return (
                                    <Box key={reviewItem.id}>
                                        <Box display="flex" alignItems="center">
                                            <Avatar style={{ height: 56, width: 56, marginRight: 10 }}>
                                                <PersonIcon fontSize="default" />
                                            </Avatar>
                                            <Box>
                                                <Typography style={{ margin: 0 }}>{reviewItem.anonymous ? `${reviewItem.user.name[0]}*****` : reviewItem.user.name}</Typography>
                                                <Rating value={reviewItem.rating} defaultValue={reviewItem.rating} precision={0.1} readOnly />
                                            </Box>
                                        </Box>
                                        <Box marginTop={2}>
                                            <Typography style={{ fontSize: 13, paddingBottom: 10 }}>
                                                {reviewItem.comment}
                                            </Typography>
                                        </Box>
                                        {
                                            _.size(reviewItem.response) > 0 ?
                                                <Box padding={2} borderRadius={10} marginBottom={1} style={{ backgroundColor: '#f1f1f1' }}>
                                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                                        <Typography className={styles.merchantReplyTitle}>{t('item.merchantReply')}</Typography>
                                                        <Typography className={styles.merchantReplyTitle}>{reviewItem.updated_date_display}</Typography>
                                                    </Box>
                                                    <Typography style={{ fontSize: 13 }}>
                                                        {reviewItem.response}
                                                    </Typography>
                                                </Box>
                                                : null
                                        }
                                        <Typography style={{ margin: 0, fontSize: 13, color: '#989898' }}>{reviewItem.created_date_display}</Typography>

                                        <Divider style={{ margin: '20px 0' }} />
                                    </Box>
                                )
                            })
                        }
                        <Box display="flex" justifyContent="center">
                            <Pagination count={lastPage} shape="rounded" page={page}
                                onChange={handlePageChange}
                                renderItem={(item) => (
                                    <PaginationItem
                                        {...item}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    :
                    // <Box display="flex" justifyContent="center" alignItems="center" minHeight={400}>
                    //     <Typography variant="body2">{t('item.noRatingReview')}</Typography>
                    // </Box>
                    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                        <WebpImg src="/images/general/nodata3.png" alt="noimage" style={{ width: '150px', maxWidth: '100%' }} />
                        <Typography variant="body2">{t('item.noRatingReview')}</Typography>
                    </Box>

            }
        </Box>
    );
}

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        color: '#525252',
        margin: 0,
        paddingRight: 7,
        fontSize: 14,
        lineHeight: 2,
        textTransform: 'uppercase'
    },
    subTitle: {
        margin: 0,
        paddingRight: 7,
        fontSize: 18,
        lineHeight: 1.5
    },
    seperator: {
        margin: '0 10px',
        fontSize: 18,
        lineHeight: 1.5,
        color: '#cecece'
    },
    sortingTitle: {
        margin: 0,
        textAlign: 'center',
        fontSize: 11,
        whiteSpace: 'nowrap'
    },
    buttonStyle: {
        width: '90%',
        backgroundColor: '#efefef',
        margin: '0px 10px',
        padding: '5px 0',
        borderRadius: 5,
        border: '1px solid'
    },
    merchantReplyTitle: {
        fontSize: 13,
        margin: 0,
        fontWeight: 'bold'
    }
}));