import { useLocation } from "react-router-dom";
import { useRef, useEffect } from 'react';

export const generateRandomString = length => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const characterLength = characters.length;

    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * characterLength));
    }
    return result;
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
  
    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export const formatAmount = amount => {
    let formatAmount = '';

	const amountString = amount.toString().replace(/\./g, '');
    const totalPad = 12 - amountString.length;
    
    let newAmount = "";
    for (var i = 0; i < totalPad; i++) {
        newAmount += "0";
    }
    
    formatAmount = newAmount + amountString;
    return formatAmount;
}

export const currencyFormat = amount => {
    if (typeof amount === 'string') {
        return amount.replace(/\d(?=(\d{3})+\.)/g, '$&,');
    } else if(typeof amount === 'decimal' || typeof amount === 'number') {
        return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    return amount;
}